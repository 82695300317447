<div id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false" [class]="menuClass">
    <div class="menu-item mb-5 mt-5" aria-haspopup="true" data-ktmenu-submenu-toggle="hover"
        *ngIf="currentTheme.baseSettings.menu.searchActive">
        <menu-search-bar></menu-search-bar>
    </div>
    <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
</div>

<ng-template #menuListTemplate>
    <ng-container *ngFor="let menu of menu">
        <div class="menu-item mt-5" [ngClass]="{'mt-0': menu?.displayName == 'Dashboard'}">
            <div class="menu-content menu-heading text-uppercase fs-7" style="color: #ffffff !important;">
                {{ menu?.displayName }}
            </div>
        </div>
        <ng-container *ngFor="let child of menu.items">
            <ng-container *ngIf="showMenuItem(child)" [ngTemplateOutlet]="mMenuItem"
                [ngTemplateOutletContext]="{ item: child, parentItem: null }"></ng-container>
        </ng-container>
    </ng-container>

    <!--begin::Settings-->
    <div class="menu-item mt-5">
        <div class="menu-content menu-heading text-uppercase fs-7" style="color: #ffffff !important;">
            Settings
        </div>
    </div>
    <div class="menu menu-rounded menu-column px-4" *ngFor="let item of settingsMenu?.items; let i = index">
        <div class="menu-item" *ngIf="i < 3" [routerLink]="item.route" routerLinkActive="active">
            <a class="menu-link">
                <span class="menu-icon">
                    <span class="bullet bullet-dot h-10px w-10px {{item?.color}}">
                    </span>
                </span>
                <span class="menu-title">
                    {{ item?.name }} 
                </span>
            </a>
        </div>

        <div class="menu-inner flex-column" *ngIf="showMore" style="transition: transform 0.3s ease-in-out;">
            <div class="menu-item" *ngIf="i >= 3" [routerLink]="item.route" routerLinkActive="active">
                <a class="menu-link">
                    <span class="menu-icon">
                        <span class="bullet bullet-dot h-10px w-10px {{item?.color}}">
                        </span>
                    </span>
                    <span class="menu-title">
                        {{ item?.name }} 
                    </span>
                </a>
            </div>
        </div>
    </div>

    <div class="menu-item" (click)="showMore = !showMore;" style="transform: translateX(0);">
        <a class="menu-link menu-collapse-toggle toggle collapsible collapsed" data-bs-toggle="collapse"
            data-kt-toggle-text="Show less">
            <span class="menu-icon">
                <i class="ki-duotone ki-down toggle-off fs-2 me-0"></i> <i
                    class="ki-duotone ki-up toggle-on fs-2 me-0"></i> </span>
            <span class="menu-title show-more-title" data-kt-toggle-text-target="true">
                {{ showMore ? 'Show less' : 'Show more'}}
            </span>
        </a>
    </div>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <div *ngIf="showMenuItem(item)" [attr.data-kt-menu-trigger]="item.items.length ? 'click' : null"
        [ngClass]="getItemCssClasses(item, parentItem)">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items.length && !item.external" [queryParams]="item.parameters" [routerLink]="item.route"
            routerLinkActive="active" class="menu-link" [ngClass]="{ 'without-sub': !item.items.length }">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>
        <a *ngIf="!item.items.length && item.external" [attr.href]="item.route" target="_blank" class="menu-link">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a href="javascript:;" *ngIf="item.items.length" class="menu-link menu-toggle"
            [class]="{ active: isMenuItemIsActive(item) }">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items.length" [class]="getSubMenuItemCssClass(item, parentItem)"
            [style]="ui.getLeftAsideSubMenuStyles()">
            <div class="menu-subnav">

                <div class="menu-item" *ngIf="iconMenu && !parentItem">
                    <!--begin:Menu content-->
                    <div class="menu-content ">
                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">{{item.name}}</span>
                    </div><!--end:Menu content-->
                </div>
                
    
                <ng-container *ngFor="let child of item.items">
                    <ng-container [ngTemplateOutlet]="mMenuItem"
                        [ngTemplateOutletContext]="{ item: child, parentItem: item }"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <!-- if menu item has submenu child  -->
    <a
    [tooltip]="iconMenu && parentItem == null ? l(item.name) : ''"
    [placement]="'right'"
    [container]="'body'"
    [adaptivePosition]="false"
    [queryParams]="item.parameters"
    [routerLink]="item.route"
    routerLinkActive="active"
    class="menu-link"
    [ngClass]="{ 'without-sub': !item.items.length }"
    >
        <span class="menu-item-here"></span>
        <!-- if menu item has icon -->
        <span class="menu-icon m-auto" *ngIf="item.icon">
            <i [ngClass]="item.icon"></i>
        </span>

        <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
            <!-- menu item title text -->
            <span class="menu-title" *ngIf="!iconMenu || parentItem != null">{{ item.name | localize }}</span>
        </ng-container>

        <ng-template #mMenuLinkBadge>
            <!-- menu item with badge -->
            <span class="menu-link-title">
                <span class="menu-link-wrap">
                    <span class="menu-text">
                        {{ item.name | localize }}
                    </span>
                    <span class="menu-link-badge">
                        <span class="badge badge-primary" [ngClass]="item.badge.type">{{ item.badge.value }}</span>
                    </span>
                </span>
            </span>
        </ng-template>

        <!-- if menu item has submenu child then put arrow icon -->
        <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="menu-arrow"></span>
    </a>
</ng-template>
<!-- END: Left Aside -->

<ng-template #msubMenuItemText let-item="item" let-parentItem="parentItem">
    <!-- if menu item has submenu child  -->
    <a
    href="javascript:;"
    *ngIf="item.items.length"
    class="menu-link menu-toggle"
    [class]="{ active: isMenuItemIsActive(item) }"
    >
        <span class="menu-item-here"></span>
        <!-- if menu item has icon -->
        <span class="menu-icon m-auto" *ngIf="item.icon">
            <i [ngClass]="item.icon"></i>
        </span>

        <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
            <!-- menu item title text -->
            <span class="menu-title" *ngIf="!iconMenu || parentItem != null">{{ item.name | localize }}</span>
        </ng-container>

        <ng-template #mMenuLinkBadge>
            <!-- menu item with badge -->
            <span class="menu-link-title">
                <span class="menu-link-wrap">
                    <span class="menu-text">
                        {{ item.name | localize }}
                    </span>
                    <span class="menu-link-badge">
                        <span class="badge badge-primary" [ngClass]="item.badge.type">{{ item.badge.value }}</span>
                    </span>
                </span>
            </span>
        </ng-template>

        <!-- if menu item has submenu child then put arrow icon -->
        <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="menu-arrow"></span>
    </a>
</ng-template>
<!-- END: Left Aside -->