<div
    appBsModal
    #clientCreationModal="bs-modal"
    (onShown)="onFocus()"
    class="modal fade create-or-edit-user-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="clientCreationModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="clientForm">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="client?.id">{{ 'Update client' | localize }}: {{ client?.name }}</span>
                        <span *ngIf="!client?.id">{{ 'Create new client' | localize }}</span>
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        (click)="closeModal()"
                        [attr.aria-label]="l('Close')"
                        [disabled]="saving"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="mb-5">
                        <label class="form-label" for="name">{{ 'Client Full Legal Name' | localize }} *</label>
                        <input
                            id="name"
                            formControlName="name"
                            type="text"
                            name="name"
                            class="form-control"
                            id="clientName"
                        />
                        <validation-messages [formCtrl]="clientForm.controls.name"></validation-messages>
                    </div>

                    <div class="d-flex">
                        <div class="mb-5 pe-2 w-100">
                            <label class="form-label" for="phone">{{ 'Phone' | localize }}</label>
                            <input
                                id="phone"
                                formControlName="phone"
                                type="text"
                                name="phone"
                                class="form-control"
                            />
                            <validation-messages [formCtrl]="clientForm.controls.phone"></validation-messages>
                        </div>

                        <div class="mb-5 ps-2 w-100">
                            <label class="form-label" for="email">{{ 'Email' | localize }} *</label>
                            <input
                                id="email"
                                formControlName="email"
                                type="email"
                                name="email"
                                class="form-control"
                            />
                            <validation-messages [formCtrl]="clientForm.controls.email"></validation-messages>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <div class="col-12 fv-row">
                            <label class="form-label" for="address1">{{ 'Address 1' | localize }} *</label>
                            <textarea
                                rows="2"
                                id="address1"
                                formControlName="address1"
                                name="address1"
                                class="form-control"
                            ></textarea>
                            <validation-messages [formCtrl]="clientForm.controls.address1"></validation-messages>
                        </div>

                        <!-- <div class="col-6 fv-row">
                            <label class="form-label" for="address2">{{ 'Address 2' | localize }} </label>
                            <textarea
                                rows="2"
                                id="address2"
                                formControlName="address2"
                                name="address2"
                                class="form-control"
                            ></textarea>
                            <validation-messages [formCtrl]="clientForm.controls.address2"></validation-messages>
                        </div> -->
                    </div>

                    <div class="row mb-5">
                        <div class="col-4 fv-row">
                            <label class="form-label" for="city">{{ 'City' | localize }}</label>
                            <input
                                id="city"
                                formControlName="city"
                                type="text"
                                name="city"
                                class="form-control"
                            />
                            <validation-messages [formCtrl]="clientForm.controls.city"></validation-messages>
                        </div>
                        <div class="col-4 fv-row">
                            <label class="form-label" for="state">{{ 'State' | localize }}</label>
                            <input
                                id="state"
                                formControlName="state"
                                type="text"
                                name="state"
                                class="form-control"
                            />
                            <validation-messages [formCtrl]="clientForm.controls.state"></validation-messages>
                        </div>
                        <div class="col-4 fv-row">
                            <label class="form-label" for="name">{{ 'Zip' | localize }}</label>
                            <input
                                id="zip"
                                formControlName="zip"
                                type="text"
                                name="zip"
                                class="form-control"
                            />
                            <validation-messages [formCtrl]="clientForm.controls.zip"></validation-messages>
                        </div>
                    </div>

                    <!-- <div class="mb-5">
                        <label class="form-label" for="description">{{ 'Client Notes/Comments' | localize }} </label>
                        <textarea
                            id="description"
                            formControlName="description"
                            type="text"
                            name="description"
                            class="form-control"
                        ></textarea>
                        <validation-messages [formCtrl]="clientForm.controls.description"></validation-messages>
                    </div> -->
                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="closeModal()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [buttonBusy]="saving"
                        [disabled]="!clientForm.valid"
                        [busyText]="l('SavingWithThreeDot')"
                        (click)="saveClient()"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ isEditMode ? 'Update' : 'Save' }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>