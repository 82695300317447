<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap" rel="stylesheet">
    <title>Invoice PDF Template</title>
</head>
<body style="margin: 0;">
    <div style="padding: 3.7rem 12.3rem; font-family: 'Open Sans', sans-serif; font-weight: 500; background-color: #fff;">
        <section style="display: flex; justify-content: space-between;">
            <img src="/assets/common/images/logo.png" style="width: 35%; margin-top: -6.1rem;">

            <div style="display: flex; flex-direction: column; width: 27%;">
                <div style="width: 3.7rem; height: 10px; background-color: black;"></div>
                <div style="font-size: 2.5rem; font-weight: bold; margin-bottom: 1.2rem;">Address</div>
                <div style="line-height: 1.5; font-size: 1.7rem;">21/A, West Carolina, New York West Minister Road, NY Down street-12345, USA.</div>
            </div>

            <div style="display: flex; flex-direction: column; width: 27%;">
                <div style="width: 3.7rem; height: 10px; background-color: black;"></div>
                <div style="font-size: 2.5rem; font-weight: bold; margin-bottom: 1.2rem;">Contact Info:</div>
                <div style="line-height: 1.5; font-size: 1.7rem;">
                    <div>+10 615 630 635-8:</div>
                    <div>myemail&#64;domain.com:</div>
                    <div>www.mywebsite.com:</div>
                </div>
            </div>
        </section>

        <div style="margin: 1.2rem 0; background-color: black; color: white; width: 55%; font-size: 4.3rem; padding-left: 14.7rem; margin-left: -12.3rem; font-weight: bold; letter-spacing: 4px; text-transform: uppercase;">
            Invoice
        </div>

        <div style="background-color: #f7f7f8; height: 5.8rem; margin-bottom: 1.2rem;"></div>

        <div style="display: flex; justify-content: space-between; padding: 0 1.2rem;">
            <div style="display: flex;">
                <div style="font-size: 1.7rem; margin-right: 4.9rem; line-height: 1.3;">
                    <div>John Smith</div>
                    <div>Second Square Building</div>
                    <div>Central Trade Road,</div>
                    <div>New York City, 1120</div>
                </div>
                <div style="font-size: 1.7rem; line-height: 1.3;">
                    <div>000.645.7878</div>
                    <div>Info&#64;companyquote.org</div>
                </div>
            </div>

            <div style="font-size: 1.7rem; line-height: 1.3; width: 28%;">
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-weight: bold;">Invoice #</div>
                    <div>00123</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-weight: bold;">Invoice Date:</div>
                    <div>02/12/2017</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-weight: bold;">P.O:</div>
                    <div>142/2017</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-weight: bold;">Due Date:</div>
                    <div>08/12/2017</div>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <div style="font-weight: bold;">Authorized By:</div>
                    <div>James Ley</div>
                </div>
            </div>
        </div>

        <div style="background-color: black; color: white; height: 5.8rem; display: flex; justify-content: space-between; align-items: center; padding: 0 3.7rem; font-size: 1.7rem; margin-top: 1.2rem;">
            <div>Description</div>
            <div>Amount</div>
        </div>

        <section>
            <div style="font-size: 2.5rem; font-weight: bold; margin: 1.2rem 0;">Installation Phase</div>
            <div style="border: 2px solid #efefef; margin-bottom: 1.2rem; display: flex; justify-content: space-between; padding: 0.6rem 3.7rem 0.6rem 1.2rem;">
                <div style="width: 90%;">
                    <div style="font-size: 2rem; font-weight: bold; margin-bottom: 0.6rem;">Underlayment Application:</div>
                    <div style="font-size: 1.7rem;">Expertly laying a waterproof barrier, providing essential protection to the deck against moisture infiltration and safeguarding the structural integrity of the roof.</div>
                </div>

                <div style="font-size: 1.7rem;">$600</div>
            </div>
            <div style="border: 2px solid #efefef; margin-bottom: 1.2rem; display: flex; justify-content: space-between; padding: 0.6rem 3.7rem 0.6rem 1.2rem;">
                <div style="width: 90%;">
                    <div style="font-size: 2rem; font-weight: bold; margin-bottom: 0.6rem;">Underlayment Application:</div>
                    <div style="font-size: 1.7rem;">Expertly laying a waterproof barrier, providing essential protection to the deck against moisture infiltration and safeguarding the structural integrity of the roof.</div>
                </div>

                <div style="font-size: 1.7rem;">$600</div>
            </div>
        </section>

        <section>
            <div style="font-size: 2.5rem; font-weight: bold; margin: 1.2rem 0;">Installation Phase</div>
            <div style="border: 2px solid #efefef; margin-bottom: 1.2rem; display: flex; justify-content: space-between; padding: 0.6rem 3.7rem 0.6rem 1.2rem;">
                <div style="width: 90%;">
                    <div style="font-size: 2rem; font-weight: bold; margin-bottom: 0.6rem;">Underlayment Application:</div>
                    <div style="font-size: 1.7rem;">Expertly laying a waterproof barrier, providing essential protection to the deck against moisture infiltration and safeguarding the structural integrity of the roof.</div>
                </div>

                <div style="font-size: 1.7rem;">$600</div>
            </div>

            <div style="margin-top: 12.3rem; display: flex; flex-direction: column; align-items: flex-end; font-size: 1.7rem; padding-right: 3.7rem;">
                <div style="display: flex; justify-content: space-between; width: 15%; margin-bottom: 1.2rem;">
                    <div>Sub Total:</div>
                    <div>$1800</div>
                </div>
                <div style="display: flex; justify-content: space-between; width: 15%;">
                    <div>Total Tax:</div>
                    <div>$100</div>
                </div>
            </div>
        </section>

        <section style="margin-top: 2.5rem;">
            <div style="background-color: #f7f7f8; height: 5.8rem; margin-bottom: 1.2rem; display: flex; align-items: center; font-size: 2rem; font-weight: bold; padding-left: .5rem;">Terms & Conditions</div>
            <div style="font-size: 1.7rem; padding-left: 1.2rem;">Payment is due within 1 week Swiss Brank, ccount No# 123444556 Routing No# 1120 A dedicated focus on
                enhancing ventilation within the roofing system through the professional installation of ridge vents,
                guaranteeing proper airflow and tempera
            </div>
        </section>
    </div>
</body>
</html>