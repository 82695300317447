<!--  Signature Template Starts  -->
<ng-container [ngSwitch]="isTemplateType">

    <!--  Static Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.StaticTemplate">
        <div class="container-fluid main-page shadow">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-row justify-content-between">
                            <h1 class="template-title">Project agreement</h1>
                        </div>
                    </div>
                    <div class="body-layout">
                        <div class="signature-subtitle">Proposal Details</div>
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Project name:</span>
                                <span class="label-description">Roof Replacement</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Project ID:</span>
                                <span class="label-description">#00238738</span>
                            </div>
                            <!-- <div class="col-4 d-flex flex-column">
                                <span class="label">
                                    Project Date:
                                </span>                
                                <span class="label-description">10/23/2024</span>
                            </div> -->
                            <div class="col-6 d-flex flex-column mt-4">
                                <span class="label">Work Commencement date (From to):</span>
                                <span class="label-description">10/23/2024 to 10/25/2024</span>
                            </div>
                            <div class="col-6 d-flex flex-column mt-4">
                                <span class="label">Estimated Completion date (From to):</span>
                                <span class="label-description">10/23/2024 to 10/25/2024</span>
                            </div>
                        </div>
                        <div class="signature-subtitle">Brand information</div>
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Manufacture or Brand name:</span>
                                <span class="label-description">Malarkey</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Color Options:</span>
                                <span class="label-description">Dual Grey</span>
                            </div>
                        </div>
                        <div class="signature-subtitle">Provisions</div>
                        <div class="p-0">
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkbox-1">
                                </div>
                                <span class="label-input-description">
                                    A 25 per cent deposit required to the start of the project. The rest of the payment
                                    is due upon job completion.
                                </span>
                            </div>
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkbox-2">
                                </div>
                                <span class="label-input-description">
                                    We reserve the right to revoke this quotation if it is not accepted within 15 days
                                    from the date of issuance.
                                </span>
                            </div>
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkbox-3">
                                </div>
                                <span class="label-input-description">
                                    This roofing contract includes a comprehensive warranty that covers all materials
                                    and workmanship for a period of 10 years from the date of installation, ensuring
                                    that any defects or failures directly related to our service will be repaired at no
                                    additional cost to the homeowner
                                </span>
                            </div>
                        </div>
                        <div class="signature-subtitle text-uppercase">Authorized signer</div>
                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="label-description">Daniel Moore</span>
                            </div>
                            <div class="col-4 d-flex flex-column">
                                <span class="label-description">000 645.8973</span>
                            </div>
                            <div class="col-4 d-flex flex-column">
                                <span class="label-description">Daneilmoore&#64;gmail.com</span>
                            </div>
                        </div>
                        <div class="signature-subtitle">Signature Section</div>
                        <div class="row">
                            <div class="col-12 d-flex flex-column mb-1">
                                <span class="label">Signature:</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="signature-box">
                                    <div class="box"></div>
                                </span>
                                <div class="d-flex flex-column mt-4">
                                    <span class="label-description fw-bold">Contractor/ Authorized Representative</span>
                                    <span class="label-description fw-normal">10/23/2024</span>
                                </div>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="signature-box"></span>
                                <div class="d-flex flex-column mt-4">
                                    <span class="label-description fw-bold">Customer/ Authorized Representative</span>
                                    <span class="label-description fw-normal">10/25/2024</span>
                                </div>
                            </div>
                        </div>
                        <div class="signature-subtitle">Approval Statement:</div>
                        <div class="p-0">
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkbox-4">
                                </div>
                                <span class="label-input-description">
                                    I certify that all answers given herin are true and complete to the best of my
                                    knowledge. I authorize investigation of all statements contained in this application
                                    for employment as may be necessary in arriving at an employment decision
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Static Template Ends  -->

    <!--  Preview Template Starts  -->
    <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
        <div class="container-fluid main-page">
            <div class="row">
                <div class="col-12">
                    <div class="template-header">
                        <div class="title-container d-flex flex-row justify-content-between">
                            <h1 class="template-title">Project agreement</h1>
                        </div>
                    </div>
                    <div class="body-layout">
                        <div class="signature-subtitle">Proposal Details</div>
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Project name:</span>
                                <span class="label-description">{{ data ? data.jsonValue.ProjectDetails.projectType : 'Roof Replacement' }}</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Project ID:</span>
                                <span class="label-description">{{ data ? data.jsonValue.ProjectDetails.projectNumber : '#00238738' }}</span>
                            </div>
                            <!-- <div class="col-4 d-flex flex-column">
                            <span class="label">
                                Project Date:
                            </span>            
                            <span class="label-description">10/23/2024</span>
                        </div> -->
                            <div class="col-6 d-flex flex-column mt-4">
                                <span class="label">Work Commencement date (From to):</span>
                                <span class="label-description">
                                    <ng-container [ngSwitch]="isTemplateType">
                                        <ng-container *ngSwitchCase="templateTypes.StaticTemplate">10/23/2024 to 10/25/2024</ng-container>
                                        <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                            {{
                                            data && data.jsonValue.ProjectDetails.workCommencementDateFromTo
                                            ? (data.jsonValue.ProjectDetails.workCommencementDateFromTo
                                            | date : 'dd/MM/YYYY')
                                            : '-'
                                            }}
                                            to
                                            {{
                                            data && data.jsonValue.ProjectDetails.workCommencementDateFromFrom
                                            ? (data.jsonValue.ProjectDetails.workCommencementDateFromFrom
                                            | date : 'dd/MM/YYYY')
                                            : '-'
                                            }}
                                        </ng-container>
                                    </ng-container>
                                </span>
                            </div>
                            <div class="col-6 d-flex flex-column mt-4">
                                <span class="label">Estimated Completion date (From to):</span>
                                <span class="label-description">
                                    <ng-container [ngSwitch]="isTemplateType">
                                        <ng-container *ngSwitchCase="templateTypes.StaticTemplate">10/23/2024 to 10/25/2024</ng-container>
                                        <ng-container *ngSwitchCase="templateTypes.PreviewTemplate">
                                            {{
                                            data && data.jsonValue.ProjectDetails.workCompletionDateFromTo
                                            ? (data.jsonValue.ProjectDetails.workCompletionDateFromTo
                                            | date : 'dd/MM/YYYY')
                                            : '-'
                                            }}
                                            to
                                            {{
                                            data && data.jsonValue.ProjectDetails.workCompletionDateFromFrom
                                            ? (data.jsonValue.ProjectDetails.workCompletionDateFromFrom
                                            | date : 'dd/MM/YYYY')
                                            : '-'
                                            }}
                                        </ng-container>
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="signature-subtitle">Brand information</div>
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Manufacture or Brand name:</span>
                                <span class="label-description">{{ data ? data.jsonValue.BrandInformation.manufacturerOrBrandName : 'Malarkey' }}</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="label">Color Options:</span>
                                <span class="label-description">{{ data ? data.jsonValue.BrandInformation.colorOptions : 'Dual Grey' }}</span>
                            </div>
                        </div>
                        <div class="signature-subtitle">Provisions</div>
                        <div class="p-0" *ngIf="!data">
                            <div class="col-12 d-flex flex-row py-2 ps-2" *ngFor="let item of data.jsonValue.FinancialWarrantyProvisions; let i = index">
                                <div class="form-check custom-form-check" [class.checked]="item.checked">
                                    <input class="form-check-input checked" type="checkbox" value="{{data.jsonValue.FinancialWarrantyProvisions[0].checked}}" id="checkbox-5">
                                </div>
                                <span class="label-input-description"> {{ item.content || '-' }}</span>
                            </div>
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check checked" [class.checked]="data.jsonValue.FinancialWarrantyProvisions[1].checked">
                                    <input class="form-check-input checked" type="checkbox" value="{{data.jsonValue.FinancialWarrantyProvisions[1].checked}}" id="checkbox-6">
                                </div>
                                <span class="label-input-description">We reserve the right to revoke this quotation if it is not accepted within 15 days from the date of issuance.</span>
                            </div>
                            <div class="col-12 d-flex flex-row py-2 ps-2">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input checked" type="checkbox" value="true" id="checkbox-7">
                                </div>
                                <span class="label-input-description">
                                    This roofing contract includes a comprehensive warranty that covers all materials
                                    and workmanship for a period of 10 years from the date of installation, ensuring
                                    that any defects or failures directly related to our service will be repaired at no
                                    additional cost to the homeowner
                                </span>
                            </div>
                        </div>

                        <!-- IsPreview -->
                        <div class="p-0" *ngIf="data">
                            <div class="col-12 d-flex flex-row py-2 ps-2" *ngFor="let item of data.jsonValue.FinancialWarrantyProvisions; let i = index">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" [class.checked]="item.checked" type="checkbox" value="" id="checkbox-1">
                                </div>
                                <span class="label-input-description">{{ item.content || '-' }}</span>
                            </div>
                        </div>
                        <div class="signature-subtitle text-uppercase">Authorized signer</div>
                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <!-- <span class="label">Name</span> -->
                                <span class="label-description">{{ data ? data.jsonValue.AuthorizedSigner.name : 'Daniel ' }}</span>
                            </div>
                            <!-- <div class="col-4 d-flex flex-column">
                                <span class="label">Last Name</span>
                                <span class="label-description">{{ data ? data.jsonValue.AuthorizedSigner.lastName : 'Moore' }}</span>
                            </div> -->
                            <div class="col-4 d-flex flex-column">
                                <!-- <span class="label">Phone</span> -->
                                <span class="label-description">{{ data ? data.jsonValue.AuthorizedSigner.phone : '000 645.8973' }}</span>
                            </div>
                            <div class="col-4 d-flex flex-column">
                                <!-- <span class="label">Email</span> -->
                                <span class="label-description">{{ data ? data.jsonValue.AuthorizedSigner.email : 'Daneilmoore@gmail.com' }}</span>
                            </div>
                            <!-- <div class="col-4 d-flex flex-column">
                                <span class="label">Date</span>
                                <span class="label-description">{{ data ? (data.jsonValue.AuthorizedSigner.date | date : 'dd/MM/YYYY') : '10/23/2024' }}</span>
                            </div> -->
                        </div>
                        <div class="signature-subtitle">Signature selection</div>
                        <div class="row">
                            <div class="col-12 d-flex flex-column mb-1">
                                <span class="label">Signature:</span>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="signature-box">
                                    <div class="box" *ngIf="!data; else signatureTemplate"></div>
                                    <ng-template #signatureTemplate>
                                        <signature-pad 
                                            [showImage]="isTemplateType === templateTypes.PreviewTemplate" 
                                            [isHideSaveButton]="true" 
                                            [isHideClearButton]="true" 
                                            [image]="quoteSignatureImage"
                                        ></signature-pad>
                                    </ng-template>
                                </span>
                                <div class="d-flex flex-column">
                                    <span class="label-description fw-bold">Contractor/ Authorized Representative</span>
                                    <span class="label-description fw-normal">
                                        {{ data.jsonValue.AuthorizedSigner.name }} {{ data.jsonValue.AuthorizedSigner.lastName }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-6 d-flex flex-column">
                                <span class="signature-box">
                                    <div class="box" *ngIf="!data; else signatureTemplateSigned"></div>
                                    <ng-template #signatureTemplateSigned>
                                        <signature-pad
                                            [showImage]="isTemplateType === templateTypes.PreviewTemplate"
                                            [isHideSaveButton]="true"
                                            [isHideClearButton]="true"
                                            [image]="signedImageNameClient"
                                        ></signature-pad>
                                    </ng-template>
                                </span>
                                <div class="d-flex flex-column">
                                    <span class="label-description fw-bold">
                                        Customer/ Authorized Representative
                                    </span>
                                    <span class="label-description fw-normal">
                                        {{ signedNameClient }}
                                    </span>
                                    <span class="label-description fw-normal">
                                       {{ signatureDate | date : 'dd/MM/YYYY' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="signature-subtitle">Approval Statement:</div>
                        <div class="p-0">
                            <div class="col-12 d-flex flex-row py-2 ps-2" *ngIf="!data">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="checkbox-7">
                                </div>
                                <span class="label-input-description">
                                    I certify that all answers given herin are true and complete to the best of my
                                    knowledge. I authorize investigation of all statements contained in this application
                                    for employment as may be necessary in arriving at an employment decision
                                </span>
                            </div>
                            <div class="col-12 d-flex flex-row py-2 ps-2" *ngIf="data">
                                <div class="form-check custom-form-check">
                                    <input class="form-check-input" [class.checked]="data.jsonValue.approvalStatement.checked" type="checkbox" value="" id="checkbox-7">
                                </div>
                                <span class="label-input-description">{{ data.jsonValue.approvalStatement.content || '-' }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="template-footer">
                        <span class="template-footer-page-no">{{ data ? data.index : '5' }}</span>
                        <span class="template-footer-company">Roof Company</span>
                    </div> -->
                </div>
            </div>
        </div>
    </ng-container>
    <!--  Preview Template Ends  -->

</ng-container>
<!--  Signature Template Ends  -->