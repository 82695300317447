import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    showGoogleCalendar:string = 'Pages.Intregration.Calender.test';

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
        this.getAllMenuItems();
    }

    getMenu(): AppMenu[] {
        return [
            // new AppMenu('Dashboard', 'Dashboard', [
            //     new AppMenuItem('Reports & Statictics', '', 'flaticon-line-graph', '/app/admin/reports'),
            // ]),
            // new AppMenu('QuoteSection', 'Quote Section', [
            //     new AppMenuItem('Quotes', '', 'bi bi-file-earmark-richtext', '/app/main/quotes'),
            //     new AppMenuItem('Invoices', '', 'bi bi-file-earmark-text', '/app/admin/invoices'),
            //     new AppMenuItem('Material List', '', 'bi bi-card-list', '/app/main/materialorders'),
            //     new AppMenuItem('Change of Order', '', 'bi bi-filter-square', '/app/admin/change-order'),
            //     new AppMenuItem('Clients', '', 'flaticon-users', '/app/admin/clients'),
            //     new AppMenuItem('Company Info', '', 'bi bi-building', '/app/admin/company-info'),
            //     new AppMenuItem('Templates', '', 'flaticon-shapes', '/app/admin/templates'),
            // ]),
            // new AppMenu('Administration', 'Administration', [
            //     new AppMenuItem('Billing', '', 'bi bi-cash-coin', '/app/admin/billing'),
            //     new AppMenuItem('Profile', '', 'flaticon-users', '/app/admin/profile'),
            // ]),
            new AppMenu('MainMenu', 'MainMenu', [
                        new AppMenuItem(
                            'Dashboard',
                            'Pages.Administration.Host.Dashboard',
                            'flaticon-line-graph',
                            '/app/admin/hostDashboard'
                        ),
                        new AppMenuItem('Performance', 'Pages.Tenant.Dashboard', 'flaticon-app', '/app/main/dashboard'),
                        new AppMenuItem('Sales', null, 'fa-solid fa-hashtag', '', [],
                        [
                               new AppMenuItem('Quotes', 'Pages.Quote', 'flaticon-share', '/app/main/quotes'),
                                new AppMenuItem('Invoices', 'Pages.Invoice', 'flaticon-line-graph', '/app/main/invoices'),
                                new AppMenuItem('Material Orders', 'Pages.MaterialList', 'flaticon-list-3', '/app/main/materialorders'),
                                new AppMenuItem('Change Orders', '', 'flaticon-attachment', '/app/main/change-order'),
                                new AppMenuItem('Work Orders', '', 'flaticon-suitcase', '/app/main/work-order'),
                                new AppMenuItem('Clients', 'Pages.Client', 'flaticon-users', '/app/admin/clients'),

                        ]),

                        new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
                        new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
                        new AppMenuItem(
                            'Administration',
                            'Pages.Administration',
                            'fa-solid fa-user-gear',
                            '',
                            [],
                            [
                                // new AppMenuItem(
                                //     'OrganizationUnits',
                                //     'Pages.Administration.OrganizationUnits',
                                //     'flaticon-map',
                                //     '/app/admin/organization-units'
                                // ),
                                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                                new AppMenuItem(
                                    'Languages',
                                    'Pages.Administration.Host.Settings',
                                    'flaticon-tabs',
                                    '/app/admin/languages',
                                    ['/app/admin/languages/{name}/texts']
                                ),
                                new AppMenuItem(
                                    'AuditLogs',
                                    'Pages.Administration.Host.Settings',
                                    'flaticon-folder-1',
                                    '/app/admin/auditLogs'
                                ),
                                new AppMenuItem(
                                    'Maintenance',
                                    'Pages.Administration.Host.Maintenance',
                                    'flaticon-lock',
                                    '/app/admin/maintenance'
                                ),
                                new AppMenuItem(
                                    'Subscription',
                                    'Pages.Administration',
                                    'flaticon-refresh',
                                    '/app/admin/subscription-management'
                                ),
                                // new AppMenuItem(
                                //     'VisualSettings',
                                //     'Pages.Administration.UiCustomization',
                                //     'flaticon-medical',
                                //     '/app/admin/ui-customization'
                                // ),
                                // new AppMenuItem(
                                //     'WebhookSubscriptions',
                                //     'Pages.Administration.WebhookSubscription',
                                //     'flaticon2-world',
                                //     '/app/admin/webhook-subscriptions'
                                // ),
                                // new AppMenuItem(
                                //     'DynamicProperties',
                                //     'Pages.Administration.DynamicProperties',
                                //     'flaticon-interface-8',
                                //     '/app/admin/dynamic-property'
                                // ),
                                new AppMenuItem(
                                    'Settings',
                                    'Pages.Administration.Host.Settings',
                                    'flaticon-settings',
                                    '/app/admin/hostSettings'
                                ),
                                new AppMenuItem('Integrations', 'Pages.Administration', 'flaticon2-world', '/app/admin/integrations'),
                                //new AppMenuItem('Mail Tracking', 'Pages.Administration', 'flaticon2-world', '/app/admin/tacking'),
                                // new AppMenuItem(
                                //     'Settings',
                                //     'Pages.Administration.Host.Settings',
                                //     'flaticon-settings',
                                //     '/app/admin/tenantSettings'
                                // ),
                                new AppMenuItem('Notifications', 'Pages.Administration', 'flaticon-alarm', '/app/notifications'),
                                new AppMenuItem(
                                    'Host Notifications',
                                    'Pages.Administration.Host.Settings',
                                    'laticon-paper-plane',
                                    '/app/admin/mass-notifications')
                            ]
                        ),
                        // new AppMenuItem(
                        //     'DemoUiComponents',
                        //     'Pages.DemoUiComponents',
                        //     'flaticon-shapes',
                        //     '/app/admin/demo-ui-components'
                        // ),

                        new AppMenuItem('Settings', null, 'flaticon-settings', '', [],
                            [
                                new AppMenuItem('Google Calendar', this.showGoogleCalendar, 'flaticon-calendar', '/app/admin/calendar'),
                                new AppMenuItem('Company Info', 'Pages.Administration', 'flaticon-info', '/app/admin/company-info'),
                                new AppMenuItem('Custom Templates', '', 'flaticon-settings', '/app/admin/qoute-setting'),
                                new AppMenuItem('Items', 'Pages.Item', 'flaticon-list-3', '/app/admin/items'),
                                // new AppMenuItem('Tax', 'Pages.Tax', 'flaticon-list-3', '/app/admin/tax'),
                                // new AppMenuItem('Units', 'Pages.Unit', 'flaticon-settings', '/app/admin/units'),
                                new AppMenuItem('Design Gallery', 'Pages.Template', 'flaticon-shapes', '/app/admin/templates'),
                                new AppMenuItem('Email Template', '', 'flaticon-shapes', '/app/main/email-template'),
                            ]),

                            new AppMenuItem('Medial Library', null, 'flaticon-upload-1', '/app/main/media-library', [],
                                []
                            ),
                    ])
        ];
    }

    getSettingsMenu(): AppMenu {
        return new AppMenu('Settings', 'Settings', [
            new AppMenuItem('Qoute Settings', '', 'flaticon-list-3', '/app/admin/qoute-setting', [], [], false, '', '', false, 'bg-primary'),
            new AppMenuItem('Items', 'Pages.Item', 'flaticon-list-3', '/app/admin/items', [], [], false, '', '', false, 'bg-success'),
            new AppMenuItem('Tax', 'Pages.Tax', 'flaticon-list-3', '/app/admin/tax', [], [], false, '', '', false, 'bg-warning'),
            new AppMenuItem('Units', 'Pages.Unit', 'flaticon-list-3', '/app/admin/units', [], [], false, '', '', false, 'bg-danger'),
            new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-list-3', '/app/admin/users', [], [], false, '', '', false, 'bg-info'),
            new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-list-3', '/app/admin/roles', [], [], false, '', '', false, 'bg-warning'),
            new AppMenuItem('Integrations', 'Pages.Intregration', 'flaticon-list-3', '/app/admin/integration', [], [], false, '', '', false, 'bg-success'),
        ])
    }

    // TODO => DELTE AFTER FINALIZATION
    // getMenu(): AppMenu {
    //     return new AppMenu('MainMenu', 'MainMenu', [
    //         new AppMenuItem(
    //             'Dashboard',
    //             'Pages.Administration.Host.Dashboard',
    //             'flaticon-line-graph',
    //             '/app/admin/hostDashboard'
    //         ),
    //         new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
    //         new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
    //         new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
    //         new AppMenuItem(
    //             'Administration',
    //             '',
    //             'flaticon-interface-8',
    //             '',
    //             [],
    //             [
    //                 new AppMenuItem(
    //                     'OrganizationUnits',
    //                     'Pages.Administration.OrganizationUnits',
    //                     'flaticon-map',
    //                     '/app/admin/organization-units'
    //                 ),
    //                 new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
    //                 new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
    //                 new AppMenuItem(
    //                     'Languages',
    //                     'Pages.Administration.Languages',
    //                     'flaticon-tabs',
    //                     '/app/admin/languages',
    //                     ['/app/admin/languages/{name}/texts']
    //                 ),
    //                 new AppMenuItem(
    //                     'AuditLogs',
    //                     'Pages.Administration.AuditLogs',
    //                     'flaticon-folder-1',
    //                     '/app/admin/auditLogs'
    //                 ),
    //                 new AppMenuItem(
    //                     'Maintenance',
    //                     'Pages.Administration.Host.Maintenance',
    //                     'flaticon-lock',
    //                     '/app/admin/maintenance'
    //                 ),
    //                 new AppMenuItem(
    //                     'Subscription',
    //                     'Pages.Administration.Tenant.SubscriptionManagement',
    //                     'flaticon-refresh',
    //                     '/app/admin/subscription-management'
    //                 ),
    //                 new AppMenuItem(
    //                     'VisualSettings',
    //                     'Pages.Administration.UiCustomization',
    //                     'flaticon-medical',
    //                     '/app/admin/ui-customization'
    //                 ),
    //                 new AppMenuItem(
    //                     'WebhookSubscriptions',
    //                     'Pages.Administration.WebhookSubscription',
    //                     'flaticon2-world',
    //                     '/app/admin/webhook-subscriptions'
    //                 ),
    //                 new AppMenuItem(
    //                     'DynamicProperties',
    //                     'Pages.Administration.DynamicProperties',
    //                     'flaticon-interface-8',
    //                     '/app/admin/dynamic-property'
    //                 ),
    //                 new AppMenuItem(
    //                     'Settings',
    //                     'Pages.Administration.Host.Settings',
    //                     'flaticon-settings',
    //                     '/app/admin/hostSettings'
    //                 ),
    //                 new AppMenuItem(
    //                     'Settings',
    //                     'Pages.Administration.Tenant.Settings',
    //                     'flaticon-settings',
    //                     '/app/admin/tenantSettings'
    //                 ),
    //                 new AppMenuItem(
    //                     'Notifications',
    //                     '',
    //                     'flaticon-alarm',
    //                     '',
    //                     [],
    //                     [
    //                         new AppMenuItem(
    //                             'Inbox',
    //                             '',
    //                             'flaticon-mail-1',
    //                             '/app/notifications'
    //                         ),
    //                         new AppMenuItem(
    //                             'MassNotifications',
    //                             'Pages.Administration.MassNotification',
    //                             'flaticon-paper-plane',
    //                             '/app/admin/mass-notifications'
    //                         )
    //                     ]
    //                 )
    //             ]
    //         ),
    //         new AppMenuItem(
    //             'DemoUiComponents',
    //             'Pages.DemoUiComponents',
    //             'flaticon-shapes',
    //             '/app/admin/demo-ui-components'
    //         ),
    //         new AppMenuItem('Settings', null, 'flaticon-settings', '', [],
    //             [
    //                 new AppMenuItem('Quote Settings', '', 'flaticon-settings', '/app/admin/quote'),
    //                 new AppMenuItem('Items', '', 'flaticon-settings', '/app/admin/items'),
    //                 new AppMenuItem('Tax', '', 'flaticon-settings', '/app/admin/tax'),
    //                 new AppMenuItem('Units', '', 'flaticon-settings', '/app/admin/units'),
    //                 new AppMenuItem('Users', '', 'flaticon-settings', '/app/admin/users'),
    //                 new AppMenuItem('Roles', '', 'flaticon-settings', '/app/admin/roles'),
    //                 new AppMenuItem('Integrations', '', 'flaticon-settings', '/app/admin/integration'),
    //             ]),
    //     ]);
    // }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu: AppMenu[] = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];

        menu.forEach((menu) => {
            menu.items.forEach((menuItem) => {
                allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
            });
        })

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
